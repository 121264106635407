import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-01";
import IntroArea from "@components/introarea/layout-1";
import LanguageGuidesOne from "@containers/language-guides/layout-4";
import HowWeWork from "@containers/global/how-we-work/layout-02";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import QuoteForm from "@containers/translation-quote-form";
const SportsTranslationServicesPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Sports Translation Services"
        description=""
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
   
      <main className="site-wrapper-reveal">
        <PageHeader data={content["sports-translation-page-header-section"]} />
        <QuoteForm />
        <PartnerArea data={content["sports-translation-logo"]} />
        <FeatureGrid data={content["sports-translation-features"]} />
        <IntroArea layout={2} data={content["sports-what-we-translate-body"]} />
        <LanguageGuidesOne layout={5} data={content["sports-localize-body"]} />
        <HowWeWork layout={5} data={content["howwework"]} />
        <CaseStudy data={caseStudiesData} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};
export const query = graphql`
  query SportsTranslationServicesPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "sports-translation" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;

SportsTranslationServicesPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default SportsTranslationServicesPage;
